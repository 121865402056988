:root {
  --color-pink: #ee415f;
  --color-green: #58ce97;
  --color-orange: #fc7d4a;
  --color-red: #d14758;
  --color-pale-pink: #ef6e85;
  --color-coral: #f3907e;
  --color-peach: #ecb38d;
  --color-aqua: #a0ded0;
  --color-pale-aqua: #c0ebe1;
  --color-lightest-blue: #f8fcff;
  --color-blue: #4c88e9;
  --color-black: #242e39;
  --color-gray-1: #3b424b;
  --color-gray-2: #68707d;
  --color-gray-3: #949fab;
  --color-gray-4: #c7cdd4;
  --color-gray-5: #edf1f6;
  --color-gray-6: #f7f9fb;
  --color-white: #fff;
}

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

.tiptapeditor {
  position: relative;
  width: 100%;
}

.ProseMirror {
  border: 2px solid var(--color-gray-4);
  border-radius: 4px;
  width: 100%;
  padding: 4px;
  overflow: auto
}

.interpretationEditor .ProseMirror {
  height: calc(100vh - 275px)
}

.addTemplateInterpretationEditor .ProseMirror {
  height: calc(100vh - 400px)
}

.interpretationTemplateEditor .ProseMirror {
  height: calc(100vh - 250px)
}

.tiptapmenu {
  top: 2px;
  left: 2px;
  z-index: 1;
  display: flex;
  align-items: center;
  gap: 8px;
  width: calc(100% - 4px);
  height: 40px;
  margin: 0;
  padding: 0 8px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom: 1px solid var(--color-gray-5);
  background-color: var(--color-white);
  color: var(--color-gray-2);
}

.menu-button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  margin: 0;
  padding: 0 8px;
  border: 0;
  border-radius: 4px;
  background: transparent;
  color: currentColor;
  font-family: inherit;
  font-size: inherit;
  line-height: 1;
  font-weight: inherit;
  white-space: nowrap;
  cursor: pointer;
}