body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
}

#table-header{
  background-color: #d3d3d3;
  font-weight: bold;
}

.phone-input {
  outline: none;
  background-color: #fbfcfe;
  border: 1px solid #cdd7e1;
  border-radius: .5rem;
  padding: 0 0.75rem;
  min-height: 2.25rem;

  &.error {
    border-color: #f09898;
    color: #c41c1c;
  }
}

.phone-input:focus {
  border: 2px solid #0b6bcb;

  &.error {
    border-color: #c41c1c;
  }
}

.fill {
  width: 100%;
  height: 100%;
}
