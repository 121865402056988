.resizeableWrapper {
  top: 0;
  left: 0;
  width: 7px;
  cursor: ew-resize;
  bottom: 0;
  padding: 4px 0 0;
  z-index: 100;
  position: absolute;
  border-top: 1px solid #ddd;
  background-color: #f4f7f9;
}